.post-list {

}

.post-item {
    width: 100%;
    margin-bottom: 40px;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid rgb(183, 179, 179);
}

.post-item-image-wrapper {
    width: 100%;
    margin-bottom: 15px;
}

.post-item-video{
    width: 100%;
     border-radius: 5px;
}
.post-item-image {
    width: 100%;
    height: 300px;
    object-fit: contain;
    border-radius: 5px;
}

.post-item-info-wrapper {
    width: 100%;
}

.post-item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    border-bottom: 1px solid var(--gray-color);
    padding-bottom: 5px;
}

.post-item-author strong {
    color: var(--green-sea-color);
    font-size: 18px;
}

.post-item-author .post-item-username {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-color);
    text-transform: capitalize;
}
.post-item-username{
    display: flex;
    flex-direction: column;
}
.post-item-date {
    font-weight: bold;
    font-size: 10px;
    color: var(--green-sea-color);
}

.post-item-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}
.post-item-likes,
.post-item-comments {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.post-item-title {
    color: var(--primary-color);
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    text-transform: capitalize;
}

.post-item-category {
    background-color: var(--pumpkin-color);
    color: var(--white-color);
    padding: 2px 7px;
    border-radius: 15px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.post-item-description {
    font-size: 17px;
    line-height: 1.9;
    color: var(--secondary-color);
    font-weight: 500;
    padding: 5px;
    margin: 10px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.post-item-link {
    background-color: var(--primary-color);
    color: var(--white-color);
    width: 100%;
    display: block;
    text-align: center;
    font-size: 21px;
    border-radius: 5px;
    padding: 5px;
}
.post-item-author{
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-item-author a{
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-item-username img{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    cursor: pointer;
    border:var(--primary-color) solid 2px;
}
/* Media Queries */
@media (max-width: 900px) {
    .post-item {
        background-color: rgba(117, 232, 250, 0.375);
    }
}
@media (max-width: 720px) {
    .post-item {
        padding: 10px;
        width: 100%;
    }
.post-list ,.post-details-video{
    width: 100%;
}

.post-item-title {
    font-size: 1rem;
}
.post-item-description{
    font-size: .8rem;
}
    .post-item-image {
        object-fit: cover;
    }
}
