@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --main-color: #d3effa;
  --primary-color: #690263;
  --primary-color-dark: #610bb1;
  --secondary-color: #544974;
  --gray-color: #778697;
  --dark-color: #292b2c;
  --blue-color: #0275d8;
  --green-color: #27ae60;
  --green-sea-color: #16a085;
  --pumpkin-color: #690263;
  --red-color: #d9534f;
  --white-color: #fff;
  --primary-hover-color: #cd9cfc;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Rubik", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}

body {
  padding: 8px;
  overflow-x: hidden;
  direction: rtl;
  position: relative;
  z-index: 0;
}
#root {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
  position:relative ;
  z-index: 0;
}
.main-container{
  min-height: 110vh;
}
.home-page-container{
  position: relative;
  z-index: 0;
}
a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

/* Some Global Classes For icons */
.bi-pencil-square {
  color: var(--green-sea-color);
  margin-right: 15px;
}

.bi-trash-fill {
  color: var(--red-color);
}

/* Scrollbar Classes For Google Chrome */
html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-track {
  background-color: rgb(187, 179, 179);
}

html::-webkit-scrollbar-thumb {
  background-color: var(--dark-color);
  border-radius: 15px;
}

/* Buttons */
.btn {
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--primary-hover-color);
  transition: all 0.3s ease;
}

/* Card styles */
.card {
  padding: 2em;
}

/* Update button */
.btn-update {
  background-color: var(--primary-color);
  padding: 20px 50px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Input styles */
input {
  background-color: transparent;
  outline: none;
}

/* Logo animation */
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1211px) {
  .navbar {
    width: 100%;
  }
  .user-dropdown {
    right: -61px;
  }
}
@media only screen and (max-width: 970px) {
  .navbar-links ul {
    gap: 0;
  }
}
@media only screen and (max-width: 900px) {
  .navbar-container {
    position: fixed;
    top: 93vh;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }
  .home {
    padding-bottom: 70px;
  }
  
  
  .side-menu.show ul {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    right: 0;
    left: 0;
    bottom: 800px;
    width: 100%;
    height: 100%;
  }
  .side-menu.show ul li {
    
    background-color: #2b33423b;
  }
  .side-menu.show ul li a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse;
    gap: 20px;
    color: #fff;
    background-color: #0274d800;
  }
  .user-dropdown {
    right: -60px;
    top: -130px;
  }
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .side-menu {
    width: 100px;
  }
  .logout-btn h2,
  .navbar-link h2,
  .navbar-link h3 {
    display: none;
  }
  .home {
    width: 100%;
  }
  section {
    padding: 0;
  }
  .post-wrapper {
    margin: 10px;
  }
  .theme-lang-container .logo {
    width: 62px;
  }
}

@media only screen and (max-width: 600px) {
  .home {
    width: 100%;
  }
  section {
    padding: 0;
  }
  .side-menu .logo {
    display: none;
  }
  .side-bar {
    height: 8%;
    width: 100%;
    position: fixed;
    top: 85vh;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    /* background-color: var(--primary-color); */
    color: #fff;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    z-index: 111;
  }

  .sidebar-container .navigation {
    height: 60px;
  }
  .side-menu-links {
    display: flex;
    flex-direction: row;
  }
  .sidebar-container {
    width: 100%;
  }
  .sidebar-container .wide {
    width: 100%;
  }
  .navbar-list {
    display: flex;
    flex-direction: row;
  }
  .sidebar-container .navigation .navbar-list li a .title {
    display: none;
  }
  .navbar-link {
    margin-bottom: 0;
  }

  .post-wrapper {
    margin: 5px;
  }

  .btn {
    padding: 0;
    background-color: var(--primary-color);
  }

  .ico {
    font-size: 30px;
  }

  .login-container {
    width: 100%;
  }

  .form,
  .login-form {
    width: 100%;
  }

  .main {
    width: 100%;
  }
  .edit-profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .btn-update {
    padding: 1rem;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
