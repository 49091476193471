.update-comment {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.update-comment-form {
   width: 700px;
   background-color: var(--white-color);
   padding: 15px;
   display: flex;
   flex-direction: column;
   position: relative;
   border-radius: 10px;
}

.update-comment-form-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--red-color);
    cursor: pointer;
    font-size: 30px;
}

.update-comment-title {
    margin-bottom: 10px;
    font-size: 24px;
    color: var(--green-sea-color);
    text-align: center;
}

.update-comment-input {
    width: 100%;
    border-radius: 10px;
    font-size: 21px;
    border: 1px solid var(--gray-color);
    padding: 10px;
    margin: 10px 0;
}

.update-comment-btn {
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: var(--green-sea-color);
    border: none;
    font-size: 21px;
    font-weight: 500;
    color: var(--white-color);
    border-radius: 10px;
    padding: 10px;
    margin-top: 15px;
}

/* Media Queries */
@media (max-width: 820px) {
    .update-comment-form {
        width: 90%;
    }
}