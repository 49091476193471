.project-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 20px;
  width: 80%;
  margin: 20px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

.project-details-section {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.project-details {
  width: 60%;
}

.project-owner {
  width: 35%;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
}

.project-description,
.project-skills,
.bid-form,
.project-share {
  margin-top: 20px;
}

.project-description h2,
.project-skills h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.project-description p,
.project-skills .skills {
  font-size: 1rem;
}

.skills span {
  display: inline-block;
  background-color: var(--primary-color);
  color: #fff;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.project-share input {
  width: 100%;
  padding: 5px;
}

.share-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.bid-form label {
  display: block;
  margin-top: 10px;
}

.bid-form input,
.bid-form textarea {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.bid-form button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  cursor: pointer;
}

.descr {
  min-height: 250px;
}

.status-open {
  color: green;
}

.status-closed {
  color: red;
}

.owner-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #ddd;
}

.project-offers {
  margin-top: 20px;
}

.project-offers h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.offer {
  overflow: hidden;
  background: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.offer p {
  margin: 5px 0;
}

.offer-owner {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.offer-owner p {
  margin: 0;
  margin-right: 10px;
  font-weight: bold;
}

.owner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.owner p {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary-color);
}

.owner img {
  width: 80px;
  border-radius: 50%;
}

.accept-btn {
  background: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .project-card {
    width: 90%;
  }

  .project-details-section {
    flex-direction: column;
  }

  .project-details,
  .project-owner {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .project-card {
    width: 95%;
    padding: 15px;
    margin-bottom: 55px;
  }

  .project-description h2,
  .project-skills h2 {
    font-size: 1.2rem;
  }

  .project-description p,
  .project-skills .skills,
  .bid-form label,
  .bid-form input,
  .bid-form textarea,
  .bid-form button,
  .skills span,
  .offer p,
  .owner p {
    font-size: 0.9rem;
  }

  .project-offers h3 {
    font-size: 1.2rem;
  }

  .accept-btn,
  .bid-form button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .owner img {
    width: 60px;
  }
}
