.project-form-container{
  min-height: 100vh;
  width:80%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px; /* Increased border radius for a softer look */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: #f9f9f9; /* Light background for better contrast */
}

.form-group {
  margin-bottom: 20px; /* Consistent spacing */
}

.description-feild{
  margin-bottom: 20px;
  height: 400px;
}

.description-feild textarea{
  height: 100%;
  resize: vertical; /* Allow vertical resizing only */
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold; /* Bold labels for better readability */
  color: #333; /* Darker color for better contrast */
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 12px; /* Increased padding for better touch targets */
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s; /* Smooth transition for focus effect */
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none; /* Remove default outline */
}

.form-group textarea {
  resize: vertical; /* Allow vertical resizing only */
}

.form-group button {
  padding: 12px 20px; /* Increased padding for better touch targets */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.form-group button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.skills-list{
  display: flex;
  flex-wrap: wrap;
}

.skill-item{
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s; /* Smooth transition for hover effect */
}

.skill-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.skill-item input {
  margin-right: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .project-form-container {
    width: 95%; /* Full width on smaller screens */
  }

  .form-group {
    margin-bottom: 15px; /* Adjust spacing for smaller screens */
  }

  .description-feild {
    height: 300px; /* Reduce height on smaller screens */
  }
}
