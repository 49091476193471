.post-details {
    width: 800px;
    margin: auto;
    padding: 20px;
}

.post-details-image-wrapper {
    height: 400px;
    margin-bottom: 30px;
}

.post-details-image,.post-details-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.update-post-label {
    font-size: 14px;
    font-weight: bold;
    color: var(--blue-color);
    cursor: pointer;
}

.update-post-label i {
    font-size: 21px;
    margin-right: 3px;
}

.update-post-image-form button {
    margin-left: 10px;
    background-color: var(--secondary-color);
    color: var(--main-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 17px;
    padding: 2px 5px;
    border: 1px solid var(--blue-color);
}

.post-details-title {
    text-align: center;
    font-size: 40px;
    color: var(--primary-color);
    text-transform: capitalize;
    margin: 10px 0;
}

.post-details-user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.post-details-user-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.post-details-user {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.post-details-user strong {
    font-size: 21px;
    color: var(--dark-color);
}

.post-details-user span {
    font-weight: 500;
    font-size: 14px;
    color: var(--gray-color);
}

.post-details-description {
    font-size: 21px;
    line-height: 1.8;
    color: var(--primary-color);
}
.bi-heart-fill{
    color: var(--primary-color);
}

.post-details-icon-wrapper {
    margin: 15px 0;
    font-size: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-color);
}

.post-details-icon-wrapper i {
    cursor: pointer;
}

.post-details-info-write {
    font-size: 17px;
    color: var(--gray-color);
}

/* Media Queries */
@media (max-width: 820px) {
    .post-details {
        width: 100%;
    }
}

@media (max-width: 520px) {
    .post-details {
        padding: 15px;
    }

    .post-details-image {
        object-fit: cover;
    }
}