.sidebar {
    /* flex: 3; */
    margin-left: 20px;
}

.sidebar-title {
    color: var(--primary-color);
    text-align: center;
    padding: 5px;
    font-size: 24px;
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.sidebar-links {
    padding: 10px;
}

.sidebar-link {
    margin: 15px 0;
    font-size: 17px;
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
    background-color: rgb(170, 168, 168);
    text-align: center;
    padding: 5px;
    position: relative;
    text-transform: capitalize;
    display: block;
    clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 5% 100%, 0% 50%, 5% 0);
}

.sidebar-link:hover {
    background-color: var(--pumpkin-color);
    color: var(--white-color);
}

.sidebar-link::after {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--white-color);
}

/* Media Queries */
@media (max-width: 990px) {
    .sidebar {
        margin-left: 0;
        width: 100%;
    }

    .sidebar-link::after {
        left: 30px;
    }
}

@media (max-width: 520px) {
    .sidebar-link::after {
        left: 20px;
    }
}