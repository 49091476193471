/* myProjects.css */

.my-project-container {
  max-width: 1200px;
  min-height: 90vh;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container.a:hover {
  background-color: var(--primary-color);
  color: #ddd;
}

.proj-list {
  list-style-type: none;
  padding: 0;
}

.proj-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.proj-item a {
  text-decoration: none;
  color: #007bff;
  margin-bottom: 10px;
}

.proj-item a:hover {
  text-decoration: underline;
}

.proj-item button {
  background: var(--primary-color);
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  max-width: 200px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.loading,
.error {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.project--card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.project--card p {
  font-size: 14px;
  line-height: 1.4;
}

/* Media Queries */

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .my-project-container {
    padding: 10px;
  }

  .proj-item {
    padding: 10px;
  }

  .proj-item button {
    font-size: 0.9rem;
  }
  .my-project-edit-del-btns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
  }
  .comment-list {
    margin-bottom: 35px;
}
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .proj-item {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .proj-item a {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .proj-item button {
    width: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .my-project-container {
    max-width: 80%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .my-project-container {
    max-width: 1200px;
  }
}