.bid-form {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.amount-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.amount-container input {
  margin-right: 10px;
  padding: 8px;
  flex: 1;
}

.fee-info {
  font-size: 14px;
  color: #333;
}

input[type="number"], textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
