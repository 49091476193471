.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-bottom: 20px;
}

.page {
    width: 40px;
    height: 40px;
    border: 1px solid var(--gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
    cursor: pointer;
}

.page.previous,
.page.next {
    width: max-content;
    padding: 0 7px;
}

.page.previous {
    border-radius: 10px 0 0 10px;
}

.page.next {
    border-radius: 0 10px 10px 0;
}

.active {
    background-color: var(--gray-color);
}

button:disabled {
    cursor: not-allowed;
}