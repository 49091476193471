.comment-list {
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0;
}

.comment-list-count {
    font-size: 30px;
    color: var(--dark-color);
    border-bottom: 1px solid var(--dark-color);
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.comment-item {
    padding: 7px;
    border: 1px solid var(--gray-color);
    border-radius: 5px;
    margin-top: 15px;
}

.comment-item-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.comment-item-username {
    font-size: 21px;
    font-weight: bold;
    color: var(--primary-color);
    text-transform: capitalize;
}

.comment-item-time {
    font-size: 15px;
    font-weight: 500;
    color: var(--pumpkin-color);
}

.comment-item-text {
    padding: 10px;
    color: var(--dark-color);
    font-size: 17px;
}

.comment-item-icon-wrapper {
    padding: 10px;
    font-size: 18px;
}

.comment-item-icon-wrapper i {
    cursor: pointer;
}