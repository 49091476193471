.posts-page {
    display: flex;
    align-items: flex-start;
    padding: 30px;
}

/* Media Queries */
@media (max-width: 990px) {
    .posts-page {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 520px) {
    .posts-page {
       padding: 15px;
    }
}