/* src/components/user.css */

/* أنماط UserItem */
.user-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-item p {
  margin: 0 10px;
  font-size: 16px;
}

.user-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-item button {
  padding: 5px 10px;
  cursor: pointer;
}

/* أنماط UserList */
.user-list {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.user-list h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
