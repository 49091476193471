.home{
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80vh;
}
.home-container {
    width: 80%;
    padding: 0 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.posts-cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-post{
    display: flex;
    gap: 5%;
    width: 140px;
    padding: 5px;
    border: var(--primary-color) 1px solid;
    border-radius: 5px;
    margin: 20px;
}

.add-post-link{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-decoration: none;
    color: var(--primary-color);
}
.add-post-link svg{
   font-size: 32px;
}
.add-post:hover {
    
    background-color: var(--primary-color);
   
   .add-post-title {
   color: #fff;
   }
   .add-post-icon {
   color: #fff;
   }
}

.home-hero-header-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.home-title {
    font-size: 40px;
    background-color: var(--main-color);
    padding: 10px 17px;
    border-radius: 21px;
    color: var(--dark-color);
    border: 2px solid var(--green-color);
}
.home-see-posts-link {
    padding: 0 30px;
    margin: 15px 0;
}

.home-see-posts-link .home-link {
    width: 100%;
    padding: 10px;
    font-size: 21px;
    background-color: var(--dark-color);
    color: var(--white-color);
    font-weight: 500;
    cursor: pointer;
    border: none;
    display: block;
    text-align: center;
    border-radius: 5px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* home.css */

.select-sort {
    padding: 8px 12px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .select-sort:focus {
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25); /* Blue shadow on focus */
    outline: none; /* Remove default outline */
  }
  
  .select-sort option {
    padding: 8px; /* Padding for options */
  }
  

/* Media Queries */
@media (max-width: 990px) {

    .home-container {
        flex-direction: column;
        align-items: center;
        padding: 0;
        width: 100%;
    }
}
@media (max-width: 900px) {

    
}

@media (max-width: 520px) {
    .home-container {
        padding: 0 15px;
    }

    .home-latest-post {
        margin-left: 15px;
    }
    
    .home-title {
        font-size: 30px;
    }
}